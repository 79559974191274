import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"

import {
  Article,
  BackgroundImage,
  Headings, Section, Typography
} from "@/atoms"
import { BreadCrumbs, Layout, NextSEO, SEO } from "@/components"
import { PolicyPageQuery } from "@/gatsby-graphql"
import { Introduction } from "@/constants/text"

export type PolicyPageProps = PageProps<PolicyPageQuery>

const Policy: React.FC<PolicyPageProps> = ({ data }) => {

  return (
    <Layout>
      {/* <SEO
        title={"事務所方針"}
        description={
          "当事務所は平成21年に開業して10年余、多くの方々の税務申告をさせていただいております。中小企業の営業と生活をまもるために、憲法にもとづく納税者の権利を擁護する立場で税務と会計の業務をおこなうよう努めております。"
        }
      /> */}
      <NextSEO
        type="Article"
        title={"事務所方針"}
        description={
          "当事務所は平成21年に開業して10年余、多くの方々の税務申告をさせていただいております。中小企業の営業と生活をまもるために、憲法にもとづく納税者の権利を擁護する立場で税務と会計の業務をおこなうよう努めております。"
        }
        url="/policy"
        breadCrumbs={[{ 'title': '事務所方針' }]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>事務所方針</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs breadCrumbs={[{ title: "事務所方針" }]} />
        <Section css={{ ...tw`space-y-6` }}>
          <div css={{ ...tw`space-y-6` }}>
            <Headings variant="h2" as='h1'>事務所について</Headings>
            <Typography prose={'base'}>
              {Introduction.office.text.map((ele, i) => <p key={i}>{ele}</p>)}
            </Typography>
          </div>
          <div css={{
            ...tw`flex flex-row space-x-6`,
            '@tablet': {
              ...tw`space-x-12`,
            }
          }}>
            <GatsbyImage
              image={data.image1.childImageSharp.gatsbyImageData}
              alt={"事務所方針イメージ1"}
              imgStyle={{ ...tw`rounded-3xl` }}
            />
            <GatsbyImage
              image={data.image2.childImageSharp.gatsbyImageData}
              alt={"事務所方針イメージ2"}
              imgStyle={{ ...tw`rounded-3xl` }}
            />
          </div>
        </Section>
      </Article>
    </Layout>
  )
}

export default Policy

export const data = graphql`
    query PolicyPage {
  image1: file(relativePath: { eq: "image1.jpg" }) {
  childImageSharp {
  gatsbyImageData(width: 500)
}
      }
image2: file(relativePath: { eq: "image2.jpg" }) {
        childImageSharp {
    gatsbyImageData(width: 500)
  }
}
placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
        childImageSharp {
    gatsbyImageData(
      layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [WEBP]
    )
  }
}
    }
`